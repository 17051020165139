export const categories = [
    {
        "filterValue": "Automobiles &amp; Accessories",
        "isoCode": "",
        "filterValueCode": "automobiles_accessories"
    },
    {
        "filterValue": "Baby &amp; Kids",
        "isoCode": "",
        "filterValueCode": "baby_kids"
    },
    {
        "filterValue": "Cash &amp; Prepaid Card",
        "isoCode": "",
        "filterValueCode": "cash_prepaid_card"
    },
    {
        "filterValue": "Charity &amp; Donations",
        "isoCode": "",
        "filterValueCode": "charity_donations"
    },
    {
        "filterValue": "Crypto Voucher",
        "isoCode": "",
        "filterValueCode": "crypto_voucher"
    },
    {
        "filterValue": "Electronics",
        "isoCode": "",
        "filterValueCode": "electronics"
    },
    {
        "filterValue": "Fashion &amp; Lifestyle",
        "isoCode": "",
        "filterValueCode": "fashion_lifestyle"
    },
    {
        "filterValue": "Food &amp; Restaurant",
        "isoCode": "",
        "filterValueCode": "food_restaurant"
    },
    {
        "filterValue": "Gaming",
        "isoCode": "",
        "filterValueCode": "gaming"
    },
    {
        "filterValue": "Gold &amp; Jewellery",
        "isoCode": "",
        "filterValueCode": "gold_jewellery"
    },
    {
        "filterValue": "Grocery",
        "isoCode": "",
        "filterValueCode": "grocery"
    },
    {
        "filterValue": "Home &amp; Living",
        "isoCode": "",
        "filterValueCode": "home_living"
    },
    {
        "filterValue": "In-house benefits",
        "isoCode": "",
        "filterValueCode": "in_house_benefits"
    },
    // {
    //     "filterValue": "Merchandise2",
    //     "isoCode": "",
    //     "filterValueCode": "merchandise2"
    // },
    {
        "filterValue": "Mobile Recharge",
        "isoCode": "",
        "filterValueCode": "mobile_recharge"
    },
    {
        "filterValue": "Perks &amp; Offers",
        "isoCode": "",
        "filterValueCode": "perks_offers"
    },
    {
        "filterValue": "Reading &amp; Learning",
        "isoCode": "",
        "filterValueCode": "reading_learning"
    },
    {
        "filterValue": "Remote Work",
        "isoCode": "",
        "filterValueCode": "remote_work"
    },
    {
        "filterValue": "Software",
        "isoCode": "",
        "filterValueCode": "software"
    },
    {
        "filterValue": "Subscriptions",
        "isoCode": "",
        "filterValueCode": "subscriptions"
    },
    {
        "filterValue": "Travel &amp; Entertainment",
        "isoCode": "",
        "filterValueCode": "travel_entertainment"
    },
    {
        "filterValue": "Wellness &amp; Sports",
        "isoCode": "",
        "filterValueCode": "wellness_sports"
    }
]