// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacybanerimg{
    width: 100%;
    object-fit: cover;
}

.PrivacyTitleTxt {
    font-size: 20px;
    color: #ff7202;
    font-weight: bolder;
    margin: 0px;
    margin-top: 0px;
}

.PrivacyPlcyMnTxtHead{
    margin-top: 40px;
    padding-left: 60px;
    padding-right: 60px;
    margin-bottom: 50px;
}

.PrivacytextOne{
    margin-top: 15px;
}

/* New class added */

.HyperLinkDesign{
    font-weight: bold;
    color: #d63366;
    text-decoration: none;
}


.HyperLinkDesign:hover{
    color:#333366;
}

.TextStyleDesign p{
    font-size: 14px;
    color: #333333;
    font-family: 'Montserrat', sans-serif;
    line-height: 20px;
}

/* New class added */

@media(max-width:768px){
    .PrivacyTitleTxt {
        font-size: 20px;
        color: #ff7202;
        font-weight: bolder;
        margin: 0px;
        margin-top: 10px;
    }
}


@media(max-width:767px){
    .PrivacyTitleTxt {
        font-size: 20px;
        color: #ff7202;
        font-weight: bolder;
        margin: 0px;
        margin-top: 10px;
    }
    .PrivacyPlcyMnTxtHead {
        margin-top: 50px;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 50px;
    }
}`, "",{"version":3,"sources":["webpack://./src/PrivacyPolicyComponent/PrivacyPolicyComponent.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,mBAAmB;IACnB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA,oBAAoB;;AAEpB;IACI,iBAAiB;IACjB,cAAc;IACd,qBAAqB;AACzB;;;AAGA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,qCAAqC;IACrC,iBAAiB;AACrB;;AAEA,oBAAoB;;AAEpB;IACI;QACI,eAAe;QACf,cAAc;QACd,mBAAmB;QACnB,WAAW;QACX,gBAAgB;IACpB;AACJ;;;AAGA;IACI;QACI,eAAe;QACf,cAAc;QACd,mBAAmB;QACnB,WAAW;QACX,gBAAgB;IACpB;IACA;QACI,gBAAgB;QAChB,kBAAkB;QAClB,mBAAmB;QACnB,mBAAmB;IACvB;AACJ","sourcesContent":[".privacybanerimg{\n    width: 100%;\n    object-fit: cover;\n}\n\n.PrivacyTitleTxt {\n    font-size: 20px;\n    color: #ff7202;\n    font-weight: bolder;\n    margin: 0px;\n    margin-top: 0px;\n}\n\n.PrivacyPlcyMnTxtHead{\n    margin-top: 40px;\n    padding-left: 60px;\n    padding-right: 60px;\n    margin-bottom: 50px;\n}\n\n.PrivacytextOne{\n    margin-top: 15px;\n}\n\n/* New class added */\n\n.HyperLinkDesign{\n    font-weight: bold;\n    color: #d63366;\n    text-decoration: none;\n}\n\n\n.HyperLinkDesign:hover{\n    color:#333366;\n}\n\n.TextStyleDesign p{\n    font-size: 14px;\n    color: #333333;\n    font-family: 'Montserrat', sans-serif;\n    line-height: 20px;\n}\n\n/* New class added */\n\n@media(max-width:768px){\n    .PrivacyTitleTxt {\n        font-size: 20px;\n        color: #ff7202;\n        font-weight: bolder;\n        margin: 0px;\n        margin-top: 10px;\n    }\n}\n\n\n@media(max-width:767px){\n    .PrivacyTitleTxt {\n        font-size: 20px;\n        color: #ff7202;\n        font-weight: bolder;\n        margin: 0px;\n        margin-top: 10px;\n    }\n    .PrivacyPlcyMnTxtHead {\n        margin-top: 50px;\n        padding-left: 20px;\n        padding-right: 20px;\n        margin-bottom: 50px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
