// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .MainAboutUsContentPage{
    padding-left: 20px;
    padding-right: 20px;
} */

.AboutUsBaneImg{
    width: 100%;
    object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/AboutUsComponent/AboutUsComponent.css"],"names":[],"mappings":"AAAA;;;GAGG;;AAEH;IACI,WAAW;IACX,iBAAiB;AACrB","sourcesContent":["/* .MainAboutUsContentPage{\n    padding-left: 20px;\n    padding-right: 20px;\n} */\n\n.AboutUsBaneImg{\n    width: 100%;\n    object-fit: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
