import React from "react";
import "./Review.css";
import { FaArrowRight } from "react-icons/fa";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { EffectCards } from 'swiper/modules';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Rating from '@mui/material/Rating';
import { reviewImage1, reviewImage2, reviewImage3 } from "./Images";
import { useNavigate } from "react-router";

const Review = () => {
    const navigate = useNavigate();

    const handleClick = (categoryName) => {
  
      if(categoryName){
        navigate(`/vouchers/${categoryName}`);
      }else {
        navigate(`/vouchers/`);
      }
    };
  return (
    <>
      <div className="reviewMainContainer">
        <div className="reviewCardContainer">
          <div className="reviewCardLeftSection">
            <div className="reviewCardLeftTopImageSection">
              <div className="blue-cirle-div">
                <div className="forsomeText">❝</div>
              </div>
            </div>
            <div className="reviewCardLeftSectionHero">
              <h3 className="heroContentText">
                What our Customers Say About US?{" "}
              </h3>
            </div>
            <div className="reviewCardLeftDescription">
              <p className="customerReviewIntro">
                Check the valuable insights and feedback shared by our esteemed
                customers about their experiences with us. Unveiling the voices
                of satisfaction and success, read testimonials from people
                around the world who have used our platform to buy gift cards
                with cryptocurrency. Their experiences showcase how easy and
                safe it is to shop, travel, give gifts and many more with
                Evouchr.
              </p>
            </div>
            <div className="reviewCardContactUs">
              <p className="customerReviewLink" onClick={() => handleClick()}>
                {" "}
                Explore Our Wide Range of Gift Cards{" "}
                <FaArrowRight style={{ marginLeft: "5px" }} />
              </p>
            </div>
          </div>
          <div className="reviewCardRightSection">
            <Swiper
              effect={"cards"}
              grabCursor={true}
              modules={[EffectCards]}
              slidesPerView={1}
              className="mySwiperReview"
              autoplay={{
                delay: 200,
                disableOnInteraction: false,
              }}
            >
              <SwiperSlide>
                <div className="reviewCardMainContainer">
                  <div className="reviewCardImageContainer">
                    <LazyLoadImage
                      alt={"review2"}
                      effect="blur"
                      src={reviewImage2}
                      className="reviewUserImage"
                    />
                  </div>
                  <span className="reviewCardName"> Andrew </span>
                  <Rating name="read-only" value={4} readOnly />
                  <div className="reviewCardTextContainer">
                    <p className="reviewCardText">
                      I've been using Evouchr for a while now, and I'm
                      impressed with the variety of gift cards available to buy
                      with cryptocurrency.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="reviewCardMainContainer">
                  <div className="reviewCardImageContainer">
                    <LazyLoadImage
                      alt={"review3"}
                      effect="blur"
                      src={reviewImage1}
                      className="reviewUserImage"
                    />
                  </div>
                  <span className="reviewCardName"> Evelyn </span>
                  <Rating name="read-only" value={4} readOnly />
                  <div className="reviewCardTextContainer">
                    <p className="reviewCardText">
                      I was hesitant at first, but Evouchr exceeded my
                      expectations. The process was smooth, and I received my
                      gift card almost instantly.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="reviewCardMainContainer">
                  <div className="reviewCardImageContainer">
                    <LazyLoadImage
                      alt={"review1"}
                      effect="blur"
                      src={reviewImage3}
                      className="reviewUserImage"
                    />
                  </div>
                  <span className="reviewCardName"> Darcy </span>
                  <Rating name="read-only" value={5} readOnly />
                  <div className="reviewCardTextContainer">
                    <p className="reviewCardText">
                    Evouchr has made my crypto spending so much more
                      versatile! I can now use my digital assets to buy gift
                      cards for my favorite brands and restaurants with ease.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
            <span className="reviewCardInformation">Swipe for more</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Review;
