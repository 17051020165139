import React from "react";  
import './AllLogoSectionComponent.css';
import { AirBnb, AmazonImg, Bitcoin, Dominos, GiftBox, Netflix, Nike, Smiley, Spotify, Uber, Xbox } from "./LogoIndex";



const AllLogoSectionComponent = () => {
    return(
        <>
            <div>
                <div className="LogoFirstRow">
                   <div>
                        <img src={AmazonImg}  className="LogoImageClass Logoone"/>
                   </div> 
                   
                   <div>
                        <img src={AirBnb} className="LogoImageClass Logotwo"/>
                   </div>
                   
                    <div>
                        <img src={Bitcoin} className="LogoImageClass Logothree"/>
                   </div> 
                   
                   <div>
                        <img src={Dominos} className="LogoImageClass Logofour"/>
                   </div>
                </div>

                <div className="LogoSecondRow">
                    <div>
                        <img src={GiftBox} className="LogoImageClass Logofive"/>
                   </div>
                   
                   <div>
                        <img src={Netflix} className="LogoImageClass Logosix"/>
                   </div>
                   
                   <div>
                        <img src={Nike} className="LogoImageClass Logoeight"/>
                   </div>
                   
                   <div>
                        <img src={Smiley} className="LogoImageClass Logonine"/>
                   </div>
                </div>

                <div className="LogoThirdRow">
                    <div>
                        <img src={Spotify} className="LogoImageClass Logoten"/>
                   </div> 
                   
                   <div>
                        <img src={Uber} className="LogoImageClass Logoelevn"/>
                   </div> 
                   
                   <div>
                        <img src={Xbox} className="LogoImageClass Logotwelve"/>
                   </div>
                </div>
            </div>
        </>
    )
}


export default AllLogoSectionComponent;