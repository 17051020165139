import React, { useState } from 'react'
import './FrequentlyAskedQuestion.css';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';


const FrequentlyAskedQuestion = () => {
  const [expanded, setExpanded] = useState(false);
  const handleChange =
    (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
   

  return (
    <>

    <div className='FAQSectionMainContainer'>

      <div className="FAQSectionTitleSection">
            <span className="FAQSectionTitleText">
                Frequently Asked Questions
            </span>
      </div>

      <div className="MainFAQContentSection">            

         <div className="LeftSideFAQSection">

            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className="MainAccordianContent">
               <AccordionSummary
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="panel1-content"
                     id="panel1-header"
                     >
                     <Typography className="AccordianHeaderTitle">What types of gift cards can I purchase with cryptocurrencies?</Typography>
               </AccordionSummary>
               <AccordionDetails>
                     <Typography className="AccordianDescTxt">
                     You can purchase a wide range of gift cards, including those for popular brands, and products. You can even pay your bills and do mobile top-ups using your preferred cryptocurrencies.
                     </Typography>
               </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')} className="MainAccordianContent">
               <AccordionSummary
               expandIcon={<ExpandMoreIcon />}
               aria-controls="panel1-content"
               id="panel1-header"
               >
               <Typography className="AccordianHeaderTitle">What cryptocurrencies are accepted on this platform?</Typography>
               </AccordionSummary>
               <AccordionDetails>
               <Typography className="AccordianDescTxt">
                  We accept 50+ wide range of cryptocurrencies, including Bitcoin, Ethereum, and many others. You can find the full list on our platform.
               </Typography>
               </AccordionDetails>
            </Accordion>
         
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className="MainAccordianContent">
               <AccordionSummary
               expandIcon={<ExpandMoreIcon />}
               aria-controls="panel3-content"
               id="panel3-header"
               >
               <Typography className="AccordianHeaderTitle"> How long does it take to receive the gift card after making a cryptocurrency payment?</Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Typography className="AccordianDescTxt">
                    Gift card delivery is typically instant, and you’ll receive it shortly after the transaction is confirmed.
                  </Typography>
               </AccordionDetails>
            </Accordion>

         </div>

         <div className="RightSideFAQSection">

            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className="MainAccordianContent">
               <AccordionSummary
               expandIcon={<ExpandMoreIcon />}
               aria-controls="panel2-content"
               id="panel2-header"
               >
               <Typography className="AccordianHeaderTitle"> I mistakenly sent my crypto to an incorrect address. What are my options?</Typography>
               </AccordionSummary>
               <AccordionDetails>
               <Typography className="AccordianDescTxt">
               Regrettably, we are unable to refund transactions in the event that you’ve sent your Bitcoin and crypto to an address different from the one provided on the payment page.
               </Typography>
               </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')} className="MainAccordianContent">
               <AccordionSummary
               expandIcon={<ExpandMoreIcon />}
               aria-controls="panel2-content"
               id="panel2-header"
               >
               <Typography className="AccordianHeaderTitle">What happens if there's an issue with my cryptocurrency transaction during the gift card purchase?</Typography>
               </AccordionSummary>
               <AccordionDetails>
               <Typography className="AccordianDescTxt">
               If you encounter any issues, our customer support team is available 24/7 to assist you and resolve the issue.
               </Typography>
               </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')} className="MainAccordianContent">
               <AccordionSummary
               expandIcon={<ExpandMoreIcon />}
               aria-controls="panel3-content"
               id="panel3-header"
               >
               <Typography className="AccordianHeaderTitle"> Is my cryptocurrency secure with Evouchr?</Typography>
               </AccordionSummary>
               <AccordionDetails>
               <Typography className="AccordianDescTxt">
               Evouchr isn’t a wallet. It enables you to instantly convert your Bitcoin and other cryptocurrencies into mobile top-ups, gift cards, and utility bill payments for numerous supported brands. We never have access to your Bitcoin or crypto beyond the amounts you send us for product purchases at Evouchr.
               </Typography>
               </AccordionDetails>
            </Accordion>

         </div>
      </div>
    </div>

  




       {/* <div className="FAQSectionMainContainer">
         <div className="FAQSectionTitleSection">
            <span className="FAQSectionTitleText">
                Frequently Asked Questions
            </span>
         </div>
         <div className="FAQSectionBottomContainer">

         <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
             <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
                >
                 
              <Typography sx={{ width: '100%', flexShrink: 0,  fontFamily: 'Montserrat',fontWeight:'bold' }}>What does Evouchr need from me to buy a Gift Card?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography style={{fontFamily: 'Montserrat'}}>
                  After payment confirmation, please double-check all your email folders, including spam, promotions, socials, or other folders.
                </Typography>
                </AccordionDetails>
             </Accordion>
            </Grid>
           
            <Grid item xs={6}>
             <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
                >
                <Typography sx={{ width: '100%', flexShrink: 0,  fontFamily: 'Montserrat',fontWeight:'bold' }}>How to buy gift cards with crypto at evouchr?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography style={{fontFamily: 'Montserrat'}}>
                    Select product, amount, and cryptocurrency
                    Add to cart and make payment
                    Get Instant email delivery
                </Typography>
                </AccordionDetails>
             </Accordion>
            </Grid>
           
            <Grid item xs={6}>
             <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
                >
            <Typography sx={{ width: '100%', flexShrink: 0,  fontFamily: 'Montserrat',fontWeight:'bold' }}>Can I use gift cards anywhere in the world?</Typography>
            </AccordionSummary>
                <AccordionDetails>
                <Typography style={{fontFamily: 'Montserrat'}}>
                 Some gift cards can be used worldwide, but many are limited to specific countries or regions. 
                Make sure to buy a gift card for the country or region you want, like the USA, EU, or Asia, before you pay.  
                </Typography>
                </AccordionDetails>
             </Accordion>
            </Grid>
           
            <Grid item xs={6}>
             <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
                >
                    
         <Typography sx={{ width: '100%', flexShrink: 0,  fontFamily: 'Montserrat',fontWeight:'bold' }}> Is it safe to use cryptocurrencies for gift card transactions on this platform?</Typography></AccordionSummary>
                <AccordionDetails>
                <Typography style={{fontFamily: 'Montserrat'}}>
                 Yes, we prioritize the security of your cryptocurrency transactions, ensuring a safe and trustworthy experience. We have integrated a popular crypto payment gateway that assures your data privacy and security.
                </Typography>
                </AccordionDetails>
             </Accordion>
            </Grid>
           
            <Grid item xs={6}>
             <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
                >
                 <Typography sx={{ width: '100%', flexShrink: 0,  fontFamily: 'Montserrat',fontWeight:'bold' }}>What happens if there's an issue with my cryptocurrency transaction during the gift card purchase?</Typography></AccordionSummary>
                <AccordionDetails>
                <Typography style={{fontFamily: 'Montserrat'}}>
                If you encounter any issues, our customer support team is available 24/7 to assist you and resolve the issue.
                </Typography>
                </AccordionDetails>
             </Accordion>
            </Grid>
           
            <Grid item xs={6}>
             <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
                >      
               <Typography sx={{ width: '100%', flexShrink: 0,  fontFamily: 'Montserrat',fontWeight:'bold' }}>How soon will I receive my gift card or recharge?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography style={{fontFamily: 'Montserrat'}}>
                Once your transaction has enough confirmations on the network, the code/pin for the gift card will be emailed to you, or the top-up will be added to the provided mobile phone number. While this process is usually almost instantaneous upon confirmation, there might be delays of up to an hour in receiving your product. Please also check your email’s spam folder. If you haven’t received the product, visit the help page
                </Typography>
                </AccordionDetails>
             </Accordion>
            </Grid>
        </Grid>
        
         </div>
       </div> */}
    </>
   )
}

export default FrequentlyAskedQuestion