// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.hamburgerMenu {
    height: 25px;
    width: 25px;
    cursor: pointer;
}


.sidebarMainContainer {
    font-family: 'Montserrat',sans-serif;
}

#react-sidebar .section-heading .text {
    margin-left: 6px;
    font-weight: 900 !important;
    font-size: larger;
}`, "",{"version":3,"sources":["webpack://./src/HeaderComponent/SidebarComponent/SidebarMenu.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,WAAW;IACX,eAAe;AACnB;;;AAGA;IACI,oCAAoC;AACxC;;AAEA;IACI,gBAAgB;IAChB,2BAA2B;IAC3B,iBAAiB;AACrB","sourcesContent":["\n.hamburgerMenu {\n    height: 25px;\n    width: 25px;\n    cursor: pointer;\n}\n\n\n.sidebarMainContainer {\n    font-family: 'Montserrat',sans-serif;\n}\n\n#react-sidebar .section-heading .text {\n    margin-left: 6px;\n    font-weight: 900 !important;\n    font-size: larger;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
