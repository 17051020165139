import React from "react";
import './SideCardsMobileComponent.css';
import SixCardMobileComponent from "./SixCardMobileComponent";


const SideCardsMobileComponent = () => {
    return(
        <>
            <div className="MainPageClassContent">
                <div className="MainPagetxtcontent">
                    <h2 className="MainPageTitletxtContent">BITVOUCHR’S Global Marketplace</h2>
                    <h3 className="MainPageTitlSubHeadTxt">Buy Anytime, Anywhere</h3>
                </div>
                <div>
                    <SixCardMobileComponent/>
                </div>
                <div className="MobilePageDescTxtContent">

                </div>
            </div>

        </>
    )
}

export default SideCardsMobileComponent



