import React from "react";
import './PrivacyPolicyComponent.css';
import {Helmet} from "react-helmet";

import PrivacyPolicyBanner from './PrivacyPolicyImages/React-Privacy-Policy.webp';

const PrivacyPolicyComponent = () =>{
    return(
        <>  
            <div className="MainPrivacyPolicyContent">

            <Helmet>
                <meta charSet="utf-8" />
                <title> Privacy Policy - Evouchr </title>
                <meta name="description"
                 content=" Discover our privacy policy for cryptocurrency gift cards, ensuring the security and confidentiality of your personal information. " />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
                    

                <div className="PrivacypolicyImageContent">
                    <img src={PrivacyPolicyBanner} className="privacybanerimg"/>
                </div>



                <div className="PrivacyPlcyMnTxtHead">
                    <div>
                        <div className="MainPrivacyTitleTxt">
                            <h2 className="PrivacyTitleTxt">We care about your privacy</h2>
                        </div>
                        <div className="MainPrivcyPageDesc TextStyleDesign">
                            <p>
                                Within this document, you can discover the details of how we utilize your data: the collection from you and your activities, 
                                processing only necessary information for requested tasks, consented purposes, or legal obligations. 
                                We employ suitable technology to safeguard your data and pledge not to share it with any party, including law enforcement, 
                                unless compelled by laws and regulations. The right to privacy is a fundamental human entitlement, 
                                and we are committed to defending yours.
                            </p>
                        </div>
                    </div>

                    <div>
                        <hr/>
                    </div>

                    <div className="PrivacytextOne">
                        <div className="MainPrivacyTitleTxt">
                            <h2 className="PrivacyTitleTxt">How do we collect your personal data?</h2>
                        </div>
                        <div className="MainPrivcyPageDesc TextStyleDesign">
                            <p>
                                We can collect your data directly (e.g. when you give it to us), indirectly (e.g. when someone else gives it to us) or through automated technologies (e.g. cookies).
                            </p>

                            <p>
                                What personal data do we collect?<br /> To utilize the platform, Evouchr does not necessitate its customers to provide any data leading to their "identification" or "identifiability," unless mandated by laws, regulations, or the specific product the customer is acquiring.
                            </p>
                            
                            <p>
                                Nevertheless, customers have the option to register an account with Evouchr, wherein they will furnish data that could potentially lead to their "identification" or "identifiability."
                            </p>
                        </div>
                    </div>

                    <div>
                        <hr/>
                    </div>


                    <div className="PrivacytextOne">
                        <div className="MainPrivacyTitleTxt">
                            <h2 className="PrivacyTitleTxt">How do we ensure the security of your personal information?</h2>
                        </div>
                        <div className="MainPrivcyPageDesc TextStyleDesign">
                            <p>
                                We are dedicated to safeguarding the privacy and confidentiality of your personal data. Access to your information is restricted solely to authorised individuals, including officers, employees, contractors, or those needing access to provide the requested services.
                            </p>
                        </div>
                    </div>

                    <div>
                        <hr/>
                    </div>


                    <div className="PrivacytextOne">
                        <div className="MainPrivacyTitleTxt">
                            <h2 className="PrivacyTitleTxt">How long is your personal data retained?</h2>
                        </div>
                        <div className="MainPrivcyPageDesc TextStyleDesign">
                            <p>
                                We retain your personal data for the duration necessary to fulfil the intended purpose of its collection.

                                For users with accounts, we retain data associated with account activity as long as the account is active.

                                Information pertinent to Bill Payment Services and other data relevant to compliance matters, such as ongoing investigations, will also be retained as needed.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
export default PrivacyPolicyComponent