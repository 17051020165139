// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HyperLinkDesign{
    font-weight: bold;
    color: #d63366;
    text-decoration: none;
}


.HyperLinkDesign:hover{
    color:#333366;
}

.TextStyleDesign p{
    font-size: 14px;
    color: #333333;
    font-family: 'Montserrat', sans-serif;
    line-height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/TermsNdConditionComponent/TermsAndCondition.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,cAAc;IACd,qBAAqB;AACzB;;;AAGA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,qCAAqC;IACrC,iBAAiB;AACrB","sourcesContent":[".HyperLinkDesign{\n    font-weight: bold;\n    color: #d63366;\n    text-decoration: none;\n}\n\n\n.HyperLinkDesign:hover{\n    color:#333366;\n}\n\n.TextStyleDesign p{\n    font-size: 14px;\n    color: #333333;\n    font-family: 'Montserrat', sans-serif;\n    line-height: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
