import React from "react";
import './CardStaticScrollComponent.css';
import { StackedImageFive, StackedImageFour, StackedImageOne, StackedImageTwo } from "../../../Images";

const StackedCardComponent = () => {
    return(
        <>
            <div>
                <div className="StackedCardmainContent">
                    <div className="StackedCardInnerScroll">
                        <div className="CardImageContentOne">
                            <img src={StackedImageOne} className="StackedCardImage"/>
                        </div> 
                        
                        <div className="CardImageContentTwo">
                            <img src={StackedImageTwo} className="StackedCardImage"/>
                        </div> 
                        
                        {/* <div className="CardImageContentThree">
                            <img src={StackedImageThree} className="StackedCardImage"/>
                        </div>  */}
                        
                        <div className="CardImageContentFour">
                            <img src={StackedImageFour} className="StackedCardImage"/>
                        </div>
                        
                        <div className="CardImageContentFive">
                            <img src={StackedImageFive} className="StackedCardImage"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StackedCardComponent