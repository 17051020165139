import footerEvouchrLogo from '../FooterImages/footerEvouchrLogo.svg';
import footerEvouchrEarth from '../FooterImages/footerEarth.png';
import footerEvouchrLanguage from '../FooterImages/footerEarthLanguage.png';
import youtubeIcon from '../FooterImages/youtube.png';
import twitterIcon from '../FooterImages/twitter.png';
import instagramIcon from '../FooterImages/instagram.png';
import facebookIcon from '../FooterImages/facebook.png';
import pinterestIcon from '../FooterImages/pinterest.png';
import evouchrFooter from '../FooterImages/Intersect.png';

export const footerEvouchrLogoImage = footerEvouchrLogo;
export const footerEvouchrEarthImage = footerEvouchrEarth;
export const footerEvouchrLanguageImage = footerEvouchrLanguage;
export const youtubeIconImage = youtubeIcon;
export const twitterIconImage = twitterIcon;
export const instagramIconImage = instagramIcon;
export const facebookIconImage = facebookIcon;
export const pinterestIconImage = pinterestIcon;

export const evouchrFooterImage = evouchrFooter;
