import React from "react";
import './AboutUsComponent.css';
import AboutUsTextPage from "./AboutUsTextPage/AboutUsTextPage";
import  AboutUsBannerImage  from './AboutUsImages/About-Us-Cover.webp';
import {Helmet} from "react-helmet";


const AboutUsComponent = () => {
    return(
        <>
            <div className="MainAboutUsContentPage">

            <Helmet>
                <meta charSet="utf-8" />
                <title>About US:Mobile Recharges and Crypto Gift Cards,Vouchers with USDT</title>
                <meta name="description"
                 content="Buy crypto gift cards and redeem crypto vouchers  Bitcoin. Enjoy the convenience of using digital assets for your favorite shopping with bitcoin." />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>


                <div>
                <a href='https://evouchr.com/contactus/'> <img src={AboutUsBannerImage} className="AboutUsBaneImg"/></a>
                </div>

                <div>
                    <AboutUsTextPage/>
                </div>
            </div>
        </>
    )
}
export default AboutUsComponent