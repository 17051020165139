// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.MainPageClassContent{
    margin-left: 110px;
    margin-right: 110px;
    text-align: center;
}

.MainPageTitletxtContent{
    font-family: 'Montserrat';
    font-size: 35px;
    text-align: center;
    margin-bottom: 0px;
}

.MainPageTitlSubHeadTxt{
    font-size: 28px;
    text-align: center;
    font-family: 'Montserrat';
    font-weight: 700;
    margin-top: 10px;  
    margin-bottom: 0px;
}

.MobilePageDescTxtContent{
    text-align: center;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 400;
    padding-left: 100px;
    padding-right: 100px;
}

.MobileScrnPhoto{
    position: relative;
}`, "",{"version":3,"sources":["webpack://./src/LandingComponent/NewLandingComponent/SideCardsMobileComponent/SideCardsMobileComponent.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["\n.MainPageClassContent{\n    margin-left: 110px;\n    margin-right: 110px;\n    text-align: center;\n}\n\n.MainPageTitletxtContent{\n    font-family: 'Montserrat';\n    font-size: 35px;\n    text-align: center;\n    margin-bottom: 0px;\n}\n\n.MainPageTitlSubHeadTxt{\n    font-size: 28px;\n    text-align: center;\n    font-family: 'Montserrat';\n    font-weight: 700;\n    margin-top: 10px;  \n    margin-bottom: 0px;\n}\n\n.MobilePageDescTxtContent{\n    text-align: center;\n    font-family: 'Montserrat';\n    font-size: 16px;\n    font-weight: 400;\n    padding-left: 100px;\n    padding-right: 100px;\n}\n\n.MobileScrnPhoto{\n    position: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
