import React from "react";
import './HeaderComponent.css';
import {MainWebLogoBit } from '../Images';
import HeaderMenuComponent from "../HeaderMenuComponent/HeaderMenuComponent";
import SidebarMenu from "./SidebarComponent/SidebarMenu";
import { categories } from '../constants/categories';
import { Helmet } from "react-helmet";



const HeaderComponent = () => {
    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Home - Buy Crypto Gift Cards and Crypto Voucher Redeem Globally</title>
                <meta name="description"
                 content="Buy crypto gift cards and redeem crypto vouchers in globally. Enjoy the convenience of using digital assets for your favorite shopping with bitcoin." />
                <meta name="keywords" content="Buy gift cards online with crypto, Crypto gift vouchers, Crypto Gift Cards, crypto virtual cards." />
                <link rel="canonical" href="https://home.evouchr.com/"/>
            </Helmet>


            <div className="HeaderContainer">
            <div className='MainWebHeader'>
                <div className='mainLogoContainer HamBurgerIconMob'>
                  <a href='https://home.evouchr.com/' className=""> 
                    <img src={MainWebLogoBit} alt='Web-logo' className='MmainLogoClass'/>
                  </a>
                
                <div className="sidebarMenuContainer">
                    <SidebarMenu allCategories={categories} />
                </div>

                </div>
                <div className="headerMenuContainer WebHeaderMenu">
                    <HeaderMenuComponent/>
                </div>
                <div className="headerButtonContainer">
                    <button className="RedirectionButtonLandingPage">
                        <a href='https://evouchr.com/'>  Get Evouchr </a>
                    </button>
                </div>
            </div>
            
        </div>       
        </>
    )
}
export default HeaderComponent