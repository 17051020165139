import React from 'react';
import './SidebarMenu.css';
import MultilevelSidebar from "react-multilevel-sidebar";
import "react-multilevel-sidebar/src/Sidebar.css";
import { useState } from 'react';
import { RxHamburgerMenu } from "react-icons/rx";
import { decodingHtml } from '../../helper/conversion';
import { useNavigate } from 'react-router-dom';

const SidebarMenu = ({allCategories}) => {
    const [IsOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();


    let options = [
        {
        //   title: "Education",
          titleIcon: <i className="fa fa-graduation-cap"></i>,
          hideBorder: false,
          disabled:false,
          content: [{ id: 1, name: "", to: "/" }]
        },
        {
          title: "Explore",
          titleIcon: <i className="fa fa-paragraph"></i>,
          hideBorder: true,
          disabled:false,
          content: [
            {
              id: 1,
              name: "Home",
              icon: <i className="fa fa-chrome"></i>,
              to: "https://evouchr.com/",
            },    

            {
              id: 1,
              name: "All Gift Cards",
              icon: <i className="fa fa-chrome"></i>,
              children: [
                {
                  titleIcon: <i className="fa fa-opera"></i>,
                  hideBorder: true,
                  content: [
                    {
                      id: 3,
                      icon: <i className="fa fa-refresh"></i>,
                      name: "All Gift Cards",
                      disabled: false,
                      to: "https://stores.evouchr.com/",
                       
                    },
                    {
                      id: 3,
                      icon: <i className="fa fa-refresh"></i>,
                      name: "Popular",
                      disabled: false,
                      to: "https://stores.evouchr.com/",
                    },
                    {
                      id: 3,
                      icon: <i className="fa fa-refresh"></i>,
                      name: "Recently Added",
                      disabled: false,
                      to: "https://stores.evouchr.com/",
                    },
                    {
                        id: 3,
                        icon: <i className="fa fa-refresh"></i>,
                        name: "Categories",
                        disabled: false,
                        children:  allCategories.length>0 ? allCategories.map(child => {
                            return {
                              titleIcon: <i className="fa fa-opera"></i>,
                              content: [
                                {
                                  id: child.id,
                                  icon: <i className="fa fa-refresh"></i>,
                                  name: decodingHtml(child.filterValue),
                                  disabled: false,
                                },
                              ]
                            }
                          }) : []
                      },
                  ]
                }
              ]
            },

            {
                id: 3,
                name: "E-Sims",
                icon: <i className="fa fa-chrome"></i>,
                to:"https://stores.evouchr.com/esims",
            },

            {
              id: 4,
              name: "About Us",
              icon: <i className="fa fa-chrome"></i>,
              to:"https://evouchr.com/aboutus/",
            }, 

            {
              id: 5,
              name: "Contact Us",
              icon: <i className="fa fa-chrome"></i>,
              to:"https://evouchr.com/contactus/",
            }  
          ]
        },
        // {
        //     // title: "Education",
        //     titleIcon: <i className="fa fa-graduation-cap"></i>,
        //     hideBorder: false,
        //     disabled:false,
        //     content: [{ id: 1, name: "eSims",}]
        // },
      
      ];
  
    const handleSidebarToggle = (IsOpen) => {
      setIsOpen(IsOpen);
    };
   
    const handleClick = (itemOptions) => {
      if(itemOptions.id !== 1 && itemOptions.id !== 3){
        navigate(`/vouchers/${itemOptions.name}`);
        handleSidebarToggle(false)
      }
    };
  return (
    <div className='sidebarMainContainer'>
        <MultilevelSidebar
          open={IsOpen}
          onToggle={handleSidebarToggle}
          options={options}
        //   header="React-MultiLevel-Sidebar"
          onItemClick={handleClick}
        />
        <RxHamburgerMenu onClick={() => handleSidebarToggle(true)} className='hamburgerMenu'/>
    </div>
  )
}

export default SidebarMenu;


  // let options = [
    //   {
    //     title: "Education",
    //     titleIcon: <i className="fa fa-graduation-cap"></i>,
    //      content: [{ id: 1, name: "My courses", to: "/my-courses" }]
    //   },
    //   {
    //     title: "Most popular",
    //     titleIcon: <i className="fa fa-paragraph"></i>,
    //     hideBorder: true,
    //     content: [
    //       {
    //         id: 2,
    //         name: "Web Development",
    //         icon: <i className="fa fa-chrome"></i>,
    //         children: [
    //           {
    //             title: "JavaScript",
    //             titleIcon: <i className="fa fa-opera"></i>,
    //             content: [
    //               {
    //                 id: 3,
    //                 name: "functions",
    //                 ["Some property i need on clicking this"]: "value"
    //               }
    //             ]
    //           }
    //         ]
    //       }
    //     ]
    //   }
    // ];