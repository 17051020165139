import React from 'react';
import './LandingComponent.css';
import { Imageeight, Imagefive, Imagefour, Imagenine, Imageone, Imageseven, Imagesix, Imagethree, Imagetwo, MainBannerImage } from '../Images';


const LandingComponent = () => {
    return (
        <>
<div>
    {/* <a href='https://stores.evouchr.com/'> */}

         <div className="LandingMainContainer">
            <div className="LandingMainHeaderContainer">
            <a href='https://evouchr.com/'> <img src={MainBannerImage} alt="Evouchr-Banner-img" className='Banner-Image'/> </a>
            </div>            
            <div className="LandingMainPageContainer">
                {/* <button className="RedirectionButtonLandingPage">
                    GET BITVOUCHR
                </button> */}
            </div>

            <div className='MainSecondSectionInfoImg'>
                <div className='InformationtxtSecondSection'>
                    <div className='LandingTitleTxt'>
                        <h2>BITVOUCHR's  Worldwide Marketplace</h2>
                    </div>
                    <div className='LandingPageMainContent'>
                        <div className='LandingPageSub'>
                            <p className='LandingPageDescTxt'>
                                    Explore an array of global brands, redeemable as gift cards, utility payments, and mobile top-ups.
                                    We also provide convenient prepaid options such as Visa and Mastercard. 
                                    Purchase <b>BITVOUCHR’s </b>extensive selection of global products using a diverse range of cryptocurrencies.
                                </p>
                            </div>
                            <div>
                                <p className='LandingPageDescTxt'>
                                    Our comprehensive range of gift card products includes a multitude of well-known brands spanning various categories. 
                                    These include Amazon, Airbnb, Xbox, UberEats, Virtual Prepaid Cards, Free Fire, eBay, GameStop, Domino’s,Paramount, Amazon, Airbnb, 
                                    PUBG, Netflix, PlayStation, Adidas, IKEA, and many more!
                                </p>
                            </div>
                            <div>
                                <p className='LandingPageDescTxt'>
                                    Discover a world of options with <b>BITVOUCHR.</b>
                                </p>
                            </div>
                            
                            <div>
                                <button className="RedirectionButtonLandingPage">
                                    <a href='https://evouchr.com/'>  Get Evouchr </a>
                                </button>
                            </div>
                            
                        </div>
                </div>
                <div className='InformationImageSectionSecond'>
                    <div className='MainCardImageContent'>
                        <div className='SecondSecntCardPt1'>
                            <div>
                                <img src={Imageone} alt='image-one' className='Card-Img-Common'/>
                            </div>

                            <div>
                                <img src={Imagetwo} alt='image-two' className='Card-Img-Common'/>
                            </div>

                            <div>
                                <img src={Imagethree} alt='image-three' className='Card-Img-Common'/>
                            </div>
                        </div>

                        <div  className='SecondSecntCardPt1'>
                            <div>
                                <img src={Imagefour} alt='image-four' className='Card-Img-Common'/>
                            </div>

                            <div>
                                <img src={Imagesix} alt='image-five' className='Card-Img-Common'/>
                            </div>
                            
                            <div>
                                <img src={Imageseven} alt='image-six' className='Card-Img-Common'/>
                            </div>
                        </div>
                        
                        <div  className='SecondSecntCardPt1'>
                            <div>
                                <img src={Imageeight} alt='image-seven' className='Card-Img-Common'/>
                            </div>

                            <div>
                                <img src={Imagenine} alt='image-eight' className='Card-Img-Common'/>
                            </div>
                            
                            <div>
                                <img src={Imagefive} alt='image-nine' className='Card-Img-Common'/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

         </div>
         {/* </a> */}

         {/* <footer>
                <FooterComponent/>
         </footer> */}
             
</div>
        </>
    )
}

export default LandingComponent;