import axios from 'axios';
import CryptoJS from 'crypto-js';

const BitvouchrAPI = axios.create({ baseURL: "https://testing.bitvouchr.com/v1/"});

const getAccessToken = async () => {
    const useremail = JSON.parse(localStorage.getItem('useremail')) || [];
    const authBody = {
      email: useremail,
    };
    return BitvouchrAPI.post('/auth/generateToken',authBody).then(response => {
      localStorage.setItem("token", JSON.stringify(response.data.token));
    });
  }

// BitvouchrAPI.interceptors.request.use(async (req) => {
//     if (localStorage.getItem('token')) {
//       const token  = JSON.parse(localStorage.getItem('token'));
//         if(token){
//             req.headers.Authorization = `Bearer ${token}`
//           }
//           const dataToHash = import.meta.env.VITE_BITVOUCHR_VENDOR;
//           const secretKey = import.meta.env.VITE_BITVOUCHR_API_KEY;
//           const hashedData = CryptoJS.HmacSHA256(dataToHash, secretKey).toString();

//           req.headers['vendor'] = import.meta.env.VITE_BITVOUCHR_VENDOR
//           req.headers['x-api-key'] = hashedData
//       }else {
//           const dataToHash = import.meta.env.VITE_BITVOUCHR_VENDOR;
//           const secretKey = import.meta.env.VITE_BITVOUCHR_API_KEY;
//           const hashedData = CryptoJS.HmacSHA256(dataToHash, secretKey).toString();

//           req.headers['vendor'] = import.meta.env.VITE_BITVOUCHR_VENDOR
//           req.headers['x-api-key'] = hashedData
//       }
//       return req;
//     },
//      error => {
//       return Promise.reject(error);
//      }
//     );
  
    
//     BitvouchrAPI.interceptors.response.use(response => {
//       return response;
//     }, error => {
//       if (error.response && error.response.status === 401) {
//            return getAccessToken().then(() => {
//                const config = error.config;
//                const token = JSON.parse(localStorage.getItem('token'));    
//                config.headers['Authorization'] = `Bearer ${token}`;
//                return axios.request(config);
//               });
//       }
//       return Promise.reject(error);
//     });
  


// get currencies
export const getCategories = () => BitvouchrAPI.get(`/bitvouchrVouchers/getFilters/voucher_category`);

// Add news Letter
export const addNewsLetter = (emailBody) => BitvouchrAPI.post(`/bitvouchrVouchers/addNewsLetter`,emailBody);