import React, { useState } from "react";
import "./FooterComponent.css";
import {
  facebookIconImage,
  evouchrFooterImage,
  footerEvouchrLogoImage,
  instagramIconImage,
  twitterIconImage,
  youtubeIconImage,
  pinterestIconImage,
} from "../FooterImages/index.js";

import useCountryCode from '../customHooks/useCountryCode';


const FooterComponent = () => {

  const { countryCode,  } = useCountryCode();


  return (
    <>
      <div className="FooterHeadContainer">
        <div className="FooterTopSectionContainer">
          <div className="FooterDetailsSection">
            <div className="logoContainerFooter">
              <a href="https://home.evouchr.com/">
                <img
                  alt={"cart icon"}
                  src={footerEvouchrLogoImage}
                  className="evouchrLogoFooter"
                  effect="blur"
                />
              </a>
            </div>
            <div className="FooterDetailsContainer">
              <div className="sectionHeading">
                <span className="sectionHeadingText">
                  Buy with Crypto
                  <hr className="dividerFooter" />
                </span>
                <li className="sectionTopicItem">
                  <a
                    href={`http://evouchr.com/${countryCode}/en`}
                    className="sectionTopicItem"
                  >
                    Gift Cards
                  </a>
                </li>
                <li className="sectionTopicItem">
                  <a
                    href={`http://evouchr.com/${countryCode}/en/esims`}

                    className="sectionTopicItem"
                  >
                    E-Sims
                  </a>
                </li>
              </div>
              <div className="sectionHeading">
                <span className="sectionHeadingText">
                  Company
                  <hr className="dividerFooter" />
                </span>
                <li className="sectionTopicItem">
                  <a
                    href="https://home.evouchr.com/aboutus/"
                    target="_blank"
                    className="sectionTopicItem"
                  >
                    About Us
                  </a>
                </li>
                <li className="sectionTopicItem">
                  <a
                    href="https://home.evouchr.com/contactus/"
                    target="_blank"

                    className="sectionTopicItem"
                  >
                    Contact Us
                  </a>
                </li>
                {/* <li className="sectionTopicItem">
                  <a
                    href="http://blogs.evouchr.com/"
                    className="sectionTopicItem"
                  >
                    Blogs{" "}
                  </a>
                </li> */}
              </div>
              <div className="sectionHeading">
                <span className="sectionHeadingText">
                  Support
                  <hr className="dividerFooter" />
                </span>
                <li className="sectionTopicItem">
                  <a
                    href="https://home.evouchr.com/faq/"
                    className="sectionTopicItem"
                  >
                    FAQs{" "}
                  </a>
                </li>
                <li className="sectionTopicItem">
                  <a
                    href="https://home.evouchr.com/privacypolicy/"
                    className="sectionTopicItem"
                  >
                    Privacy Policy{" "}
                  </a>
                </li>
                <li className="sectionTopicItem">
                  <a
                    href="https://home.evouchr.com/termsandconditions/"
                    className="sectionTopicItem"
                  >
                    Terms and Conditions
                  </a>
                </li>
              </div>
            </div>
          </div>
          <div className="FooterRightDetailsSection">
            <img
              alt={"cart icon"}
              src={evouchrFooterImage}
              className="evouchrBackgroundImage"
              effect="blur"
            />
            <div className="SocialConnectionContainer">
              <span className="StayConnectedText">STAY CONNECTED</span>
              <div className="FooterSocialMediaSection">
                <a href="https://www.youtube.com/channel/UCcysPc7rvxvNsJZIs-1DgmQ" target="_blank">
                  <img
                    alt={"country flag"}
                    effect="blur"
                    src={youtubeIconImage}
                    className="socialLogo"
                  />
                </a>
                <a href="https://x.com/evouchr" target="_blank">
                  <img
                    alt={"country flag"}
                    effect="blur"
                    src={twitterIconImage}
                    className="socialLogo"
                  />
                </a>
                <a href="https://www.instagram.com/evouchr_/" target="_blank">
                  <img
                    alt={"country flag"}
                    effect="blur"
                    src={instagramIconImage}
                    className="socialLogo"
                  />
                </a>
                <a href="https://www.facebook.com/profile.php?id=61560523571906" target="_blank">
                  <img
                    alt={"country flag"}
                    effect="blur"
                    src={facebookIconImage}
                    className="socialLogo"
                  />
                </a>
                <a href="https://www.pinterest.com/Evouchr_/" target="_blank">
                  <img
                    alt={"country flag"}
                    effect="blur"
                    src={pinterestIconImage}
                    className="socialLogo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="FooterBottomContainer">
          <hr className="copyrightsDivider" />
          <div className="FooterCopyRightSection">
            <span className="footerbottomas">Copyright © 2024 Evouchr </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default FooterComponent;
