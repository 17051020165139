import React from "react";
import './ContactUsComponent.css';
import ContactusImage from './React-Contact-Form.webp';
import { TextField, TextareaAutosize } from "@mui/material";
import ContactUsForm from "./ContactUsForm";
import '../ContactUsComponent/ContactUsComponent.css';

const ContactUsComponent = () => {
    return(
        <>
            <div>
                <div className="ContactMainContainer">
                    <div  className="ContactLeftSide">
                        <div className="ContactTxtImageContent">
                            <div>
                                <h2 className="ContacUsTitleTxt">Are you prepared to explore how we can assist you with gift cards, utility payments, and mobile top-ups worldwide?</h2>
                            </div>
                            <div className="ContactUsTxtSecDecs">
                            We hold the key to achieving your objectives!
                            </div>
                            <div className="ContactUsTxtSecDecs">
                            Complete the form with your inquiries, and we’ll reach out to you within a couple of hours
                            </div>
                            <div>
                                <img src={ContactusImage} className="ContactUsBannerImg"/>
                            </div>
                        </div>
                    </div>

                    <div className="ContactRightSide">
                        <ContactUsForm/>                      
                    </div>
                </div>
            </div>
        </>
    )
}
export default ContactUsComponent