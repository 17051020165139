import React from "react";
import './NewLandingMobileScrollSection.css';

const NewLandingMobileScrollSection =() => {
    return(
        <>
          <div>
            <div className="LandingPageTxtalign">
                {/* <span className="LandingPageMainTitleTxt">Global Gift <br/> Card Hub</span> */}
                <div className="LandingPageMainTitleTxt">Global Gift <br/> Card Hub </div>

               <a href="https://evouchr.com/"> <button className="GetevouchrButtonBlck">Get EVOUCHR</button></a>
            </div>
          </div>
        </>
    )
}

export default NewLandingMobileScrollSection
