import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import './HeaderMenuComponent.css';
import { getCategories } from '../network/evouchrAPI';
import { categories } from '../constants/categories';
// import './HeaderMenu.css';
// import { ItemNotFoundImage } from '../../../images/headerIcons';
import useCountryCode from '../customHooks/useCountryCode';


const HeaderMenuComponent = () => {
    const [allCategories, setAllCategories] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const navigate = useNavigate();
    const baseUrl = 'https://evouchr.com/';

    const { countryCode, isLocationAllowed, failSafeWorked } = useCountryCode();




    const handleMouseEnter = () => {
        setShowDropdown(true);
    };
    
    const handleMouseLeave = () => {
        setShowDropdown(false);
      };
        
  // const decodeHTML = (html) => {
  //   const txt = document.createElement('textarea');
  //   txt.innerHTML = html;
  //   return txt.value;
  // };


  const decodeHTML = (html) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    const decoded = txt.value; // "Baby & Kids"
    
    // Replace & and other special characters with hyphens, remove extra spaces
    const formatted = decoded
        .replace(/&/g, 'and')     // Replace & with 'and'
        .replace(/\s+/g, '-')     // Replace spaces with hyphens
        .replace(/[^a-zA-Z0-9-]/g, '') // Remove any remaining special characters
        .toLowerCase(); 
    return formatted; // "Baby-and-Kids"
};


 
  
  const handleMenuItemClick = (categoryName) => {

    const baseUrl = `https://evouchr.com/${countryCode}/en`;
    if(categoryName){
        window.open(`${baseUrl}/giftcards/${categoryName}`, '_blank');
      }else {
        window.open(`${baseUrl}`, '_blank');
      }
    };
  
//   const fetchCategories = async () => {
//     try {
//     const response = await getCategories();
//     const filters = response.data?.data[0];

//     if(filters.filters.length>0){
//         setAllCategories(filters.filters);
//       }

//   return response.data;
//     } catch (error) {
//         console.log(error);
//     }
//  };

//   useEffect(() => {
//   fetchCategories();
//   }, []);
  
// console.log(categories)

  return (
    <>
      <div className="headerMenuMainContainer">
        <ul className="headerMenu">
            <li className="headerMenuItem"  onClick={() => handleMenuItemClick()} >
              Home
            </li>
            <li className="headerMenuItem" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleMenuItemClick()} >
                 All Gift Cards
            </li>
           
            <li className="headerMenuItem"  onMouseEnter={handleMouseLeave}>
              <a href={`https://evouchr.com/${countryCode}/en/esims`} target="_blank"  className='headerMenuLink' >
                E-Sims
              </a>
            </li>
            
            {/* <li className="headerMenuItem"  onMouseEnter={handleMouseLeave} onClick={() => handleMenuItemClick("Mobile Recharge")}> 
               Phone Refills
            </li> */}
            <li className="headerMenuItem"  onMouseEnter={handleMouseLeave}> 
              <a href="https://home.evouchr.com/aboutus/" target="_blank" className='headerMenuLink' >
               About Us
              </a>
            </li>
            <li className="headerMenuItem"  onMouseEnter={handleMouseLeave} > 
            <a href="https://home.evouchr.com/contactus/" target="_blank" className='headerMenuLink'  >
               Contact Us
               </a>
            </li>
        </ul>
        {showDropdown && (
            <div className="dropdown-menu-container"  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <div className="dropdownContentContainer">
                <div className="dropdown-categoryContainer">
                    <div className="dropdown-titleContainer">
                        <h3 className="dropdown-categoryTitleText">Categories</h3>
                    </div>
                     <div className="dropdown-bottomContainer">
                         <div className="grid-container">
                            {
                             categories.map((category, index) => {
                              return(
                                    <p className="grid-item" onClick={() => handleMenuItemClick(decodeHTML(category.filterValue) )}
                                     dangerouslySetInnerHTML={{ __html: decodeHTML(category.filterValue) }} key={index}/>
                                    )
                                  }
                                )
                              }
                            </div>
                     </div>
                </div>
                <div className="dropdown-popularContainer">
                  <div className="popular-container">
                      <div className="dropdown-titleContainer">
                            <h3 className="dropdown-categoryTitleText">Trending in {"your city"}</h3>
                        </div>
                        <div className="popularinfo-container">
                          <ul className="tobBrandslistContainer">
                            <li className="topBrandsItem">Amazon</li>
                            <li className="topBrandsItem">Google</li>
                            <li className="topBrandsItem">Uber</li>
                            <li className="topBrandsItem">Master card</li>
                            <li className="topBrandsItem">Airbnb</li>
                          </ul>
                        </div>
                    </div>
                </div>
                {/* <div className="dropdown-QuickLinksContainer">
                 <div className="featured-container">
                    <div className="dropdown-titleContainer">
                            <h3 className="dropdown-categoryTitleText">Quick Check</h3>
                        </div>
                        <div className="popularinfo-container">
                        <ul className="tobBrandslistContainer">
                            <li className="topBrandsItem"  onClick={() => handleMenuItemClick()}><h4>All Gift Cards </h4></li>
                            <li className="topBrandsItem"  onClick={() => handleMenuItemClick()}><h4>Recently Added </h4></li>
                          </ul>
                        </div>
                    </div>
                 </div> */}
              </div>
            </div>
          )}
  </div>
    </>
  )
}

export default HeaderMenuComponent