import React from "react";
import { CiGift } from "react-icons/ci";
import './CardStaticScrollComponent.css';
import '../../MobileSecondCenterSection/MobileSecondCenterSection.css';
import StackedCardComponent from "./StackedCardComponent";


const CardStaticScrollComponent = () => {
    return(
        <>
            <div className="CardStaticsScroll">
                <div className="ScrlSectionHeight">
                    <div className="CardtxtContentSecti">
                        <h2 className="StackScrollTitleTxt">Simplify Gift-Giving</h2>
                        <p className="StackScrollSubHeadTxt">with Crypto Gift Vouchers</p>
                        <div className="StackScrollDescTxt">
                            <b>EVOUCHR</b> is loaded with a wide collection of gift cards with top brands,thoughtfully made for any occasion and daily needs.
                        </div>

                        <div className="StackScrollDescTxt2">
                            Shop for the perfect gift voucher everytime you look for a gift.
                        </div>

                        <div className="StackScrollButtonStyle">
                            <a href="https://evouchr.com/">
                                <button type="button" data-hover="Click here" className="StartGiftingButton">   
                                    <span>Start Gifting Today <CiGift className="GiftIconBtn"/> </span> 
                                </button>
                            </a>
                        </div>
                    </div>

                    <div>
                        <StackedCardComponent/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CardStaticScrollComponent