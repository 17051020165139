// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GetevouchrButtonBlck{
    background: #000;
    color: #fff;
    padding: 9px 20px 9px 20px;
    border-radius: 20px;
    border: none;
    font-weight: bolder;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}

.GetevouchrButtonBlck:hover{
    transform:scale(1.1);
    transition: all 1s ease;
}`, "",{"version":3,"sources":["webpack://./src/LandingComponent/NewLandingComponent/NewLandingMobileScrollSection/NewLandingMobileScrollSection.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;IACX,0BAA0B;IAC1B,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,oBAAoB;IAEpB,uBAAuB;AAC3B","sourcesContent":[".GetevouchrButtonBlck{\n    background: #000;\n    color: #fff;\n    padding: 9px 20px 9px 20px;\n    border-radius: 20px;\n    border: none;\n    font-weight: bolder;\n    margin-top: 15px;\n    margin-bottom: 15px;\n    cursor: pointer;\n}\n\n.GetevouchrButtonBlck:hover{\n    transform:scale(1.1);\n    -webkit-transition: all 1s ease;\n    transition: all 1s ease;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
