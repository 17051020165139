import React from "react";
import { EmptyPhoneBackground, ProductDetils } from "../../../Images";


const SixCardMobileComponent = () => {
    return(

        <>
            <div>
                <div>
                    <div>
                        <img src="" className="MobileLftCardOne"/>
                    </div>

                    <div>
                        <img src="" className="MobileLftCardTwo"/>
                    </div>

                    <div>
                        <img src="" className="MobileLftCardThree"/>
                    </div>
                </div>

                
                <div className="MobileScrnPhoto">
                    <div>
                        <img src={EmptyPhoneBackground} />
                    </div>
                    <div>
                        <img src={ProductDetils} />
                    </div>
                </div>

                
                <div>
                    
                    <div>
                        <img src="" className="MobileRightCardOne"/>
                    </div>

                    <div>
                        <img src="" className="MobileRightCardTwo"/>
                    </div>

                    <div>
                        <img src="" className="MobileRightCardThree"/>
                    </div>
                </div>
            </div>
        
        </>

    )
}

export default SixCardMobileComponent;