import React from "react";
import './NewLandingComponent.css';
import AllLogoSectionComponent from "../AllLogoSectionComponent/AllLogoSectionComponent";
import NewLandingMobileScrollSection from "./NewLandingMobileScrollSection/NewLandingMobileScrollSection";
import MobileSecondCenterSection from "../MobileSecondCenterSection/MobileSecondCenterSection";
import NewCardCollageSection from "./NewCardCollageSection/NewCardCollageSection";
import CardStaticScrollComponent from "./CardStaticScrollComponent/CardStaticScrollComponent";
import SideCardsMobileComponent from "./SideCardsMobileComponent/SideCardsMobileComponent";
import FrequentlyAskedQuestion from "../FrequentlyAskedQuestion/FrequentlyAskedQuestion";
import OnBoarding from "../OnBoarding/OnBoarding";
import Review from "../ReviewSectionComponent/Review";
import Newsletters from "../NewsletterComponent/Newsletters";



const NewLandingComponent = () => {
    return(
        <>

            <div className="MainNewLandingComponent">
                <div>
                    <AllLogoSectionComponent/>
                </div>

                <div>
                    <NewLandingMobileScrollSection/>
                </div>

                <div>
                    <MobileSecondCenterSection/>
                </div>

                <div>
                    <NewCardCollageSection/>
                </div>

                <div>
                    <CardStaticScrollComponent/>
                </div>

                <div className="OnboardingContainer">
                    <OnBoarding/>
                </div>

                <div>
                    <Review/>
                </div>

                <div  className="FAQSectionContainer">
                    <FrequentlyAskedQuestion/>
                </div>

                <div className="NewsLettersSection">
                    <Newsletters/>
                </div>
              {/* 
                <div>
                    <SideCardsMobileComponent/>
                </div> */}

            </div>
        
        </>
    )
}

export default NewLandingComponent