// import user1 from './User1.webp';
// import user2 from './User2.webp';
// import user3 from './User3.webp';


// export const reviewImage1 = user1;
// export const reviewImage2 = user2;
// export const reviewImage3 = user3;

export const reviewImage1 = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/reviewprofile/User1.webp';
export const reviewImage2 = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/reviewprofile/User2.webp';
export const reviewImage3 = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/reviewprofile/User3.webp';
