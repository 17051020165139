import React, { useState } from "react";
import './ContactUsComponent.css';
import { TextField } from "@mui/material";
import '../ContactUsComponent/ContactUsComponent.css';
import emailjs from "@emailjs/browser";

const ContactUsForm = () => {
    const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);


  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const templateParams = {
      from_name:name,
      from_email:email,
      from_phone:phone,
      from_description:description,
    };

    emailjs.send('service_d78ydq9', 'template_dilot2t', templateParams, 'm1Fu_uj1UAbT7Ti6I')
      .then((response) => {
        console.log('Email sent successfully!', response.status, response.text);
        alert('We Got Your Email Thanks');
        setLoading(false);
      })
      .catch((err) => {
        console.error('Failed to send email:', err);
        alert('Failed to send email. Please try again later.');
        setLoading(false);
      });
  };


  return (
    <>
      <div>
        <form className="FormTetFiledDesign" onSubmit={handleSubmit}>
        <TextField
          className="ContactUsTxtBox"
          fullWidth
          label="Enter Your Name"
          variant="outlined"
          type="text"
          name="name"
          value={name}
          onChange={handleNameChange}
          margin="normal"
          placeholder="Example: John Cena"
          required
        />

        <TextField
          className="ContactUsTxtBox"
          fullWidth
          label="Enter Your Email"
          variant="outlined"
          type="email"
          name="email"
          value={email}
          onChange={handleEmailChange}
          margin="normal"
          placeholder="Example: Jhon@gmail.com"
          required
        />

        <TextField
          className="ContactUsTxtBox"
          fullWidth
          label="Enter Your Phone"
          variant="outlined"
          type="text"
          name="phone"
          value={phone}
          onChange={handlePhoneChange}
          margin="normal"
          placeholder="Example: 999-999-9999"
          required
        />

        <TextField
          className="ContactUsTxtBox"
          fullWidth
          label="Enter Your Description"
          variant="outlined"
          type="text"
          name="description"
          value={description}
          onChange={handleDescriptionChange}
          margin="normal"
          placeholder="Example: Write something..."
          required
          rows={4}
          multiline
        />

        <button className="RedirectionButtonLandingPage" type="submit" disabled={loading}>
         Submit
        </button>
      </form>
      </div>
    </>
  );
};

export default ContactUsForm;
