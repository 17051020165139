import React, { useState } from "react";
import './FAQComponent.css';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import FAQBannerImage from './React-FAQ.webp';


const FAQComponent = () => {


    const [expanded, setExpanded] = useState(false);
    const handleChange =
      (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
      };
     



        
    return(
        <>

                <div className="FAQImageSection">
                    <img src={FAQBannerImage} className="FAQBannerImage"/>
                </div>

            <div className="MainFAQContentSection">            

                <div className="LeftSideFAQSection">

                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className="MainAccordianContent">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            >
                            <Typography className="AccordianHeaderTitle">1. I didn’t receive the gift card I paid for</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="AccordianDescTxt">
                                After payment confirmation, please double-check all your email folders, including spam, promotions, socials, or other folders.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className="MainAccordianContent">
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                        >
                        <Typography className="AccordianHeaderTitle">2. How to buy gift cards with crypto at evouchr?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography className="AccordianDescTxt">
                            <b>Choose, Order, Pay:</b>

                        1. Select product, amount, and cryptocurrency <br/>
                        2. Add to cart and make payment<br/>
                        3. Get Instant email delivery
                        </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className="MainAccordianContent">
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                        >
                        <Typography className="AccordianHeaderTitle">3. What payment methods are eligible?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography className="AccordianDescTxt">
                        Evouchr provides a convenient method to utilize Bitcoin and various cryptocurrencies for gift card payments.  Purchase gift cards with your cryptocurrency. As brands don’t accept Bitcoin or other cryptocurrencies directly.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} className="MainAccordianContent">
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                        >
                        <Typography className="AccordianHeaderTitle">4. Can I use gift cards anywhere in the world?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography className="AccordianDescTxt">
                        Some gift cards can be used worldwide, but many are limited to specific countries or regions. Make sure to buy a gift card for the country or region you want, like the USA, EU, or Asia, before you pay.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} className="MainAccordianContent">
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                        >
                        <Typography className="AccordianHeaderTitle">5. What does Evouchr need from me to buy a Gift Card?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography className="AccordianDescTxt">
                        All we need is your email to send you order details, the product code/pin, and instructions on how to use it. If you’re buying top-ups, we need your mobile number.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} className="MainAccordianContent">
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                        >
                        <Typography className="AccordianHeaderTitle">6. What types of gift cards can I purchase with cryptocurrencies?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography className="AccordianDescTxt">
                        You can purchase a wide range of gift cards, including those for popular brands, and products. You can even pay your bills and do mobile top-ups using your preferred cryptocurrencies.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')} className="MainAccordianContent">
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                        >
                        <Typography className="AccordianHeaderTitle">7. I purchased a gift card for the incorrect country. What are my options?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography className="AccordianDescTxt">
                        Regrettably, we cannot refund or replace the gift card once it has been successfully delivered to the email address. Please be mindful before making a purchase, as certain gift cards are designated for specific countries or regions and may not be valid outside of the designated country or region for redemption.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')} className="MainAccordianContent">
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                        >
                        <Typography className="AccordianHeaderTitle">8. Is it safe to use cryptocurrencies for gift card transactions on this platform?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography className="AccordianDescTxt">
                        Yes, we prioritize the security of your cryptocurrency transactions, ensuring a safe and trustworthy experience. We have integrated a popular crypto payment gateway that assures your data privacy and security.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')} className="MainAccordianContent">
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                        >
                        <Typography className="AccordianHeaderTitle">9. What cryptocurrencies are accepted on this platform?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography className="AccordianDescTxt">
                        We accept 50+ wide range of cryptocurrencies, including Bitcoin, Ethereum, and many others. You can find the full list on our platform.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')} className="MainAccordianContent">
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                        >
                        <Typography className="AccordianHeaderTitle">10. How long does it take to receive the gift card after making a cryptocurrency payment?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography className="AccordianDescTxt">
                        Gift card delivery is typically instant, and you’ll receive it shortly after the transaction is confirmed.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>


                <div className="RightSideFAQSection">

                    <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')} className="MainAccordianContent">
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        >
                        <Typography className="AccordianHeaderTitle">11. What happens if there's an issue with my cryptocurrency transaction during the gift card purchase?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography className="AccordianDescTxt">
                        If you encounter any issues, our customer support team is available 24/7 to assist you and resolve the issue.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')} className="MainAccordianContent">
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                        >
                        <Typography className="AccordianHeaderTitle">12. Do you offer any special promotions or discounts to buy gift cards?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography className="AccordianDescTxt">
                        Yes, we frequently offer exclusive promotions and discounts, providing additional value for your purchases.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')} className="MainAccordianContent">
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                        >
                        <Typography className="AccordianHeaderTitle">13. What countries does evouchr work in?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography className="AccordianDescTxt">
                        Currently, we serve the USA, Europe, and Asia. As we continue to expand, new countries will be added systematically, ensuring all legal requirements are fully met.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')} className="MainAccordianContent">
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                        >
                        <Typography className="AccordianHeaderTitle">14. Is my cryptocurrency secure with Evouchr?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography className="AccordianDescTxt">
                        Evouchr isn’t a wallet. It enables you to instantly convert your Bitcoin and other cryptocurrencies into mobile top-ups, gift cards, and utility bill payments for numerous supported brands. We never have access to your Bitcoin or crypto beyond the amounts you send us for product purchases at Evouchr.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel15'} onChange={handleChange('panel15')} className="MainAccordianContent">
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                        >
                        <Typography className="AccordianHeaderTitle">15. Which mobile operators does Evouchr accommodate?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography className="AccordianDescTxt">
                        Check for supported countries by selecting a country flag from the dropdown menu and entering a mobile phone number to see if it is supported.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel16'} onChange={handleChange('panel16')} className="MainAccordianContent">
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                        >
                        <Typography className="AccordianHeaderTitle">16. How soon will I receive my gift card or recharge?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography className="AccordianDescTxt">
                        Once your transaction has enough confirmations on the network, the code/pin for the gift card will be emailed to you, or the top-up will be added to the provided mobile phone number. While this process is usually almost instantaneous upon confirmation, there might be delays of up to an hour in receiving your product. Please also check your email’s spam folder. If you haven’t received the product, visit the help page.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel17'} onChange={handleChange('panel17')} className="MainAccordianContent">
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                        >
                        <Typography className="AccordianHeaderTitle">17. I mistakenly sent my crypto to an incorrect address. What are my options?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography className="AccordianDescTxt">
                        Regrettably, we are unable to refund transactions in the event that you’ve sent your Bitcoin and crypto to an address different from the one provided on the payment page.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel18'} onChange={handleChange('panel18')} className="MainAccordianContent">
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                        >
                        <Typography className="AccordianHeaderTitle">18. I have an unanswered question. How can I seek assistance?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography className="AccordianDescTxt">
                        We’re here to help! If you have any questions not covered here, don’t hesitate to contact us. Your satisfaction is important to us, and we’re happy to offer prompt and helpful responses. For additional support, reach out to us through our Contact page.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>


                    <Accordion expanded={expanded === 'panel19'} onChange={handleChange('panel19')} className="MainAccordianContent">
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                        >
                        <Typography className="AccordianHeaderTitle">19. I love Evouchr! Can I promote it?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography className="AccordianDescTxt">
                        Certainly! We adore you too! You can help us by engaging on our social media platforms. Follow us, and show some love by liking and sharing our page on social media channels. Your support holds great significance for us!
                        </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel20'} onChange={handleChange('panel20')} className="MainAccordianContent">
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                        >
                        <Typography className="AccordianHeaderTitle">20. Do I need to create an account to shop?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography className="AccordianDescTxt">
                        Creating an account is optional, but it comes with added advantages. Simply verify your email address, and you can enjoy numerous benefits while shopping.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>

            </div>
        </>
    )
}
export default FAQComponent