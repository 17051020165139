import React from 'react';
import './OnBoarding.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { arrowIconImage, cardIconImage, paymentIconImage } from '../../Images/onboardingIcons';

const OnBoarding = () => {
  
  
  return (
    <div className="OnBoardingContainer">
        <span className="OnBoardingTitle">
           How Gift Cards Work
        </span>
         <div className="OnBoardingCardContainer">
            <div className="OnBoardingCard">
                <span className="OnBordingCardTitle">Pick Your Gift Card</span>
                 <p className="OnBordingCardDetails">Find the gift card you want to purchase from top brands.</p>
                 <div className="taskContainer">
                   <input
                    type="text"
                    placeholder="Amazon"
                    disabled
                    className='cardInput'
                    />
                    <LazyLoadImage
                      alt={'arrow Icon'}
                      src={arrowIconImage}
                      className='cardArrowIcon'
                      effect="blur"
                     />
                 </div>
            </div>
            <div className="OnBoardingCard">
              <span className="OnBordingCardTitle">Pay with Your Crypto</span>
                 <p className="OnBordingCardDetails">Choose your preferred cryptocurrency from the list and make the payment.</p>
                 <div className="taskContainer">
                   <LazyLoadImage
                      alt={'arrow Icon'}
                      src={paymentIconImage}
                      className='cardPaymentIcon'
                      effect="blur"
                      />
                 </div>
            </div>
            <div className="OnBoardingCard">
              <span className="OnBordingCardTitle"> You're Ready to Go!</span>
                 <p className="OnBordingCardDetails">Redeem the card using the code on the brand's official website or at their store. </p>
                 <div className="taskContainer">
                 <LazyLoadImage
                    alt={'card Icon'}
                    src={cardIconImage}
                    className='creditCardIcon'
                    effect="blur"
                    />
                 </div>
            </div>
         </div>
    </div>
  )
}

export default OnBoarding