import React from "react";
import './AboutUsTextPage.css';
import WebCountryImage from '../AboutUsImages/Web-Country-Image-React.webp';
import { FaArrowRight } from "react-icons/fa";
import { Helmet } from "react-helmet";


const AboutUsTextPage = () => {

    
    <Helmet>
        <meta charSet="utf-8" />
        <title> About US:Mobile Recharges and Crypto Gift Cards,Vouchers with USDT </title>
        <meta name="description"
        content="Explore a variety of gift cards, Top-up your mobile effortlessly, and redeem vouchers seamlessly using USDT. Enjoy secure and fast transactions for digital purchases." />
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
    </Helmet>


    return(
        <>
        <div className="MainAboutUsTextContent">
            <div className="AboutUsTitleTxt">
                    <h2> About Us </h2>
                <div className="AboutUsTxtSec">
                    <p className="AboutUsTxtSecDecs">
                        <b>Evouchr</b> is a vertical of VirtualMinds Portal brand, a company with huge experience in leading diverse industries across the globe. 
                        The mission is to make use of cryptocurrency everyday, making it convenient for people.
                    </p>
                    
                    <p className="AboutUsTxtSecDecs">
                        This concept of using <b>cryptocurrency</b> to buy crypto vouchers online for paying bills, gifting, cards that can be used in daily life, 
                        mobile recharges with crypto on a global scale was started with this vision. 
                        We have gathered a highly experienced dedicated team making it a smooth shopping experience with crypto for our users.
                    </p>
                </div>

                <div className="AboutContUsBtn">
                    <button className="RedirectionButtonLandingPage">
                        <a href="https://evouchr.com/contactus/"> Contact Us <FaArrowRight  className="BtnIconCls"/></a>
                    </button>
                </div>

            </div>


           <div className="SecondSectnMain"> 
                <div className="AboutUsTitleTxt AboutPartition">
                            <h2> One-Stop Gift <span className="TitleColoredChnge">Card Destination</span> </h2>
                        <div className="AboutUsTxtSec">
                            <p className="AboutUsTxtSecDecs">
                                <b>Evouchr</b> is a vertical of VirtualMinds Portal brand, a company with huge experience in leading diverse industries across the globe. 
                                The mission is to make use of cryptocurrency everyday, making it convenient for people.
                            </p>
                            
                            <p className="AboutUsTxtSecDecs">
                                This concept of using <b>cryptocurrency</b> to buy crypto vouchers online for paying bills, gifting, cards that can be used in daily life, 
                                mobile recharges with crypto on a global scale was started with this vision. 
                                We have gathered a highly experienced dedicated team making it a smooth shopping experience with crypto for our users.
                            </p>

                            <div className="AboutContUsBtn">
                                <button className="RedirectionButtonLandingPage">
                                    <a href="https://evouchr.com/"> Shop Now <FaArrowRight  className="BtnIconCls"/></a>
                                </button>
                            </div>
                        </div>
                </div>

                <div className="AboutPartitionImage">
                    <img src={WebCountryImage} className="CountryWebImage"/>
                </div>
           </div>

            <div className="SecondSectnMain AboutSecMrg">
                <div className="AboutUsTitleTxt">
                    <h3 className="OurGoalTitleTxt">  Our Goal  </h3>
                        <h2> Simplifying the Art of Gifting! </h2>
                            <div className="AboutUsTxtSec">
                                <p className="AboutUsTxtSecDecs">
                                    At <b>EVOUCHR,</b> we’ve made the ideal gifts for every occasion. 
                                    We hold a strong belief that every gift card you receive will create a lasting memory and make meaningful connections. 
                                    Our aim centres on the use of cryptocurrency for everyday happiness and gratitude across the globe. 
                                    Uniting people and facilitating various lifestyles and celebrating milestones in professional or personal life,
                                    we aim to foster a world of shared happiness, joy and appreciation. 
                                </p>
                            </div>

                            <div className="AboutContUsBtn">
                                <button className="RedirectionButtonLandingPage">
                                    <a href="https://evouchr.com/"> Start Gifting Now <FaArrowRight  className="BtnIconCls"/></a>
                                </button>
                            </div>
                </div>
            </div>
        

        </div>
            
        </>
    )
}
export default AboutUsTextPage