import React, { useRef, useState } from "react";
import './MobileSecondCenterSection.css';
import { CiGift } from "react-icons/ci";
import MobileVideo from '../MobileSecondCenterSection/Video-Cutter-Third-Evouchr-1.0.mp4';

const MobileSecondCenterSection = () => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlay = () => {
        if (videoRef.current) {
            videoRef.current.play();
            setIsPlaying(true); // Hide the button after clicking play
        }
    };

    return (
        <div>
            <div className="MainCenterSection">
                <div className="Mainheadingsection">
                    <div className="mainHeadTxtSect">
                        <h2>Buy Crypto <br/> Gift Cards</h2>
                    </div>
                    <div>
                        <a href="https://home.evouchr.com/">
                            <button type="button" data-hover="Click here" className="StartGiftingButton">   
                                <span>Start Gifting Today <CiGift className="GiftIconBtn"/> </span> 
                            </button>
                        </a>
                    </div>
                </div>
                <div className="MainVideoPlayingSect">
                    <div>
                        <video
                            ref={videoRef}
                            loop
                            muted
                            preload="auto"
                            className="VideoMainSection"
                            // controls
                        >
                            <source src={MobileVideo} type="video/mp4" />
                        </video>
                        {!isPlaying && (
                            <button onClick={handlePlay} className="VideoPlayButton">Play Video</button> // Button is hidden when the video starts playing
                        )}
                    </div>
                </div>
                <div className="SecondingheadingSection">
                    <div className="scndHeadingTextSect">
                        <h2>A Thoughtful Gift of Choice</h2>
                    </div>
                    <div className="scndDescTxtSect">
                        <p>
                            Ensuring the use of cryptocurrency- the future of payments, for everyday use and expenses or to give as a gift for every significant occasion, and special moment. Shopping with bitcoin, ethereum, tether and many other cryptocurrency is easy now with Evouchr. 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileSecondCenterSection;
