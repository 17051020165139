
  export const decodingHtml =  (html) => {
    try {
         
      const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    
    } catch (error) {
        console.error('Error fetching currency conversion:', error);
        return null;
    }
};