import { useState, useEffect } from 'react';
import useGeoLocation from "react-ipgeolocation";

const useCountryCode = () => {
  const [countryCode, setCountryCode] = useState("US");
  const [isLocationAllowed, setIsLocationAllowed] = useState(false);
  const [failSafeWorked, setFailSafeWorked] = useState(true);
  const location = useGeoLocation();

  const lookup = {
    US: "United States",
    CA: "Canada",
    GB: "United Kingdom",
    AU: "Australia",
    IN: "India",
    // Add other country codes and names as needed
  };


  useEffect(() => {
    const fetchCountryByIP = async () => {
      try {
        console.log("Fetching country by IP...");
        const response = await fetch('https://ipapi.co/json');
        const data = await response.json();
  
        console.log("API Response:", data);

        if (response?.status === 200) {
          const { country_name, country_code } = data;
          console.log(`Country Name: ${country_name}, Country Code: ${country_code}`);

          // Set the country code to lowercase
          setCountryCode(country_code.toLowerCase());
          setIsLocationAllowed(true);
          console.log("Location allowed:", true);
        } else {
          handleLocationFallback();
        }
      } catch (error) {
        console.error("Error fetching country by IP:", error.message);
        handleLocationFallback();
      }
    };

    const handleLocationFallback = () => {
        if (location.error || location.country === undefined) {
          console.log("No location data, defaulting to United States");
          setCountryCode("US".toLowerCase());
          setIsLocationAllowed(false);
          setFailSafeWorked(false);
        } else {
          const country = lookup[location.country];
          console.log(`Country from geolocation: ${country}`);
      
          // Set the country code to lowercase
          setCountryCode(location.country.toLowerCase());
          setIsLocationAllowed(true);
          setFailSafeWorked(true);
        }
      };
      

    fetchCountryByIP();
  }, [location.country]);

  return { countryCode, isLocationAllowed, failSafeWorked };
};

export default useCountryCode;
