// import AmazonLogo from './LogoImages/Amazon-Whte-brd (1).webp';
// import AirBnbLogo from './LogoImages/arnabpnb-2 (1).webp';
// import BitcoinLogo from './LogoImages/Bitcoin (1).webp';
// import DominosLogo from './LogoImages/Dominos (1).webp';
// import GiftBoxLogo from './LogoImages/Gift-box (1).webp';
// import NetflixLogo from './LogoImages/Netflix-2 (1).webp';
// import NikeLogo from './LogoImages/Nike (1).webp';
// import SmilyLogo from './LogoImages/Smily (1).webp';
// import SpotifyLogo from './LogoImages/spotify (1).webp';
// import UberLogo from './LogoImages/uber (1).webp';
// import XboxLogo from './LogoImages/Xbox (1).webp';



// export const AmazonImg = AmazonLogo;
// export const AirBnb = AirBnbLogo;
// export const Bitcoin = BitcoinLogo;
// export const Dominos = DominosLogo;

// export const GiftBox = GiftBoxLogo;
// export const Netflix = NetflixLogo;
// export const Nike = NikeLogo;
// export const Smiley = SmilyLogo;

// export const Spotify = SpotifyLogo;
// export const Uber = UberLogo;
// export const Xbox = XboxLogo;


export const AmazonImg = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/floatingimages/Amazon-Whte-brd.webp';
export const AirBnb = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/floatingimages/arnabpnb.webp';
export const Bitcoin = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/floatingimages/Bitcoin.webp';
export const Dominos = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/floatingimages/Dominos.webp';

export const GiftBox = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/floatingimages/Gift-box.webp';
export const Netflix = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/floatingimages/Netflix.webp';
export const Nike = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/floatingimages/Nike.webp';
export const Smiley = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/floatingimages/Smily.webp';

export const Spotify = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/floatingimages/spotify.webp';
export const Uber = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/floatingimages/uber.webp';
export const Xbox = 'https://evouchrspace.blr1.cdn.digitaloceanspaces.com/Landing/floatingimages/Xbox.webp';